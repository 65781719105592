import { navigate } from "@reach/router";
import { Button } from "antd";
import { ColumnProps } from "antd/lib/table";
import idx from "idx";
import GET_BUYER_LIST from "./getBuyerList.graphql";
import {
  GetBuyerList,
  GetBuyerList_buyers_items,
  GetBuyerListVariables,
} from "./types/GetBuyerList";
import Authorize from "components/authorize";
import GraphQLTable, {
  creationTime,
  lastModificationTime,
  useColumnSearch,
} from "components/graphQLTable";
import { ImportBuyersModal } from "components/import";
import MainLayout from "components/layouts/main";
import React, { FC, useCallback, useMemo, useState } from "react";
import routes from "routes";

type Buyer = GetBuyerList_buyers_items;

const BuyerList: FC = () => {
  const [importModalVisible, setImportModalVisible] = useState(false);

  const showImportModal = useCallback(() => setImportModalVisible(true), [
    setImportModalVisible,
  ]);

  const hideImportModal = useCallback(() => setImportModalVisible(false), [
    setImportModalVisible,
  ]);

  const handleBuyerView = useCallback(
    (id: string) => () => navigate(routes.buyerViewToPath({ id })),
    []
  );

  const { createColumn: createNameColumn } = useColumnSearch<Buyer>("name");

  const { createColumn: createINNColumn } = useColumnSearch<Buyer>("inn");

  const columns = useMemo<Array<ColumnProps<Buyer>>>(
    () => [
      { dataIndex: "id", title: "#", sorter: true },
      createNameColumn({
        title: "Имя",
        sorter: true,
      }),
      createINNColumn({ title: "ИНН", sorter: true }),
      creationTime,
      lastModificationTime,
      {
        key: "action",
        render: (_text, { id }) => (
          <Button
            ghost={true}
            icon="edit"
            size="small"
            type="primary"
            onClick={handleBuyerView(`${id}`)}
          >
            Ред.
          </Button>
        ),
      },
    ],
    [createINNColumn, createNameColumn, handleBuyerView]
  );

  const getDataSource = useCallback(
    (data?: GetBuyerList) => idx(data, x => x.buyers),
    []
  );

  return (
    <Authorize>
      <MainLayout
        title="Покупатели"
        extra={
          <Button icon="import" onClick={showImportModal}>
            Импорт
          </Button>
        }
      >
        <GraphQLTable<Buyer, GetBuyerList, GetBuyerListVariables>
          columns={columns}
          query={GET_BUYER_LIST}
          getDataSource={getDataSource}
        />
        <ImportBuyersModal
          visible={importModalVisible}
          onCancel={hideImportModal}
        />
      </MainLayout>
    </Authorize>
  );
};

export default BuyerList;
